@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/rubik/Rubik-normal-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/rubik/Rubik-normal-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/rubik/Rubik-normal-500.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/rubik/Rubik-normal-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/rubik/Rubik-normal-900.ttf) format('truetype');
}

