@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/nunito/Nunito-normal-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/nunito/Nunito-normal-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/nunito/Nunito-normal-500.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/nunito/Nunito-normal-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/nunito/Nunito-normal-900.ttf) format('truetype');
}

