@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-900.ttf) format('truetype');
}

