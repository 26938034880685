@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/robotoslab/Roboto-Slab-normal-100.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/robotoslab/Roboto-Slab-normal-200.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/robotoslab/Roboto-Slab-normal-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/robotoslab/Roboto-Slab-normal-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/robotoslab/Roboto-Slab-normal-500.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/robotoslab/Roboto-Slab-normal-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/robotoslab/Roboto-Slab-normal-900.ttf) format('truetype');
}

