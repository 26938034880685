@mixin ui-border-top-radius($radius) {
  @if $ui-enable-rounded {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }
}
@mixin ui-border-right-radius($radius) {
  @if $ui-enable-rounded {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin ui-border-bottom-radius($radius) {
  @if $ui-enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin ui-border-left-radius($radius) {
  @if $ui-enable-rounded {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  }
}

