// WebKit-style focus

@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: 0;
  outline-offset: 0;
}


@mixin grayscale($value) {
  -webkit-filter: #{"grayscale(#{$value})"};
  -moz-filter: #{"grayscale(#{$value})"};
  filter:#{"grayscale(#{$value})"};
}