@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/playfairDisplay/Playfair-Display-normal-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/playfairDisplay/Playfair-Display-normal-500.ttf) format('truetype');
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/playfairDisplay/Playfair-Display-normal-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/playfairDisplay/Playfair-Display-normal-900.ttf) format('truetype');
}

